<template>
  <CampaignForm
    :campaign="campaign"
    :loading="loading"
    :errors="errors"
    :readonly="readonly"
    @submit="onSubmit"
  >
    <VxBtn
      v-if="!readonly"
      data-testid="campaignFormEndButton"
      color="error"
      type="button"
      large
      text
      :block="isMobile"
      :disabled="loading"
      @click="onEndCampaignClick"
    >
      {{ $t("bounceback.setup.editCampaign.endCampaignButton") }}
    </VxBtn>

    <VxConfirmDialog
      v-model="showEndCampaignConfirmDialog"
      :title="$t('bounceback.setup.editCampaign.endCampaignConfirm.title')"
      :ok-text="
        $t('bounceback.setup.editCampaign.endCampaignConfirm.confirmText')
      "
      @ok="onEndCampaignConfirmDialogOk"
    >
      <p>
        {{ $t("bounceback.setup.editCampaign.endCampaignConfirm.description") }}
      </p>
      <p>
        {{
          $t("bounceback.setup.editCampaign.endCampaignConfirm.description2")
        }}
      </p>
    </VxConfirmDialog>

    <VxConfirmDialog
      v-model="showNewCampaignConfirmDialog"
      :title="$t('bounceback.setup.editCampaign.newCampaign.title')"
      @ok="onNewCampaignConfirmDialogOk"
    >
      <p>
        {{ $t("bounceback.setup.editCampaign.newCampaign.description") }}
      </p>
      <p>
        {{ $t("bounceback.setup.editCampaign.newCampaign.description2") }}
      </p>
      <p>
        {{ $t("bounceback.setup.editCampaign.newCampaign.description3") }}
      </p>
    </VxConfirmDialog>
  </CampaignForm>
</template>

<script>
import { hasSnackbarAccess } from "@/mixins/ui";
import { GET_CAMPAIGN_BY_ID } from "./graphql/queries";
import CampaignForm from "./components/CampaignForm/CampaignForm.vue";

import { isPast, isToday, parseISO } from "date-fns";
import { VxBtn, VxConfirmDialog, useResponsiveness } from "@/core-ui";
import {
  UPDATE_CAMPAIGN,
  END_CAMPAIGN,
  CLONE_CAMPAIGN,
} from "./graphql/mutations";

export default {
  name: "EditCampaignView",
  components: {
    VxBtn,
    VxConfirmDialog,
    CampaignForm,
  },
  mixins: [useResponsiveness(), hasSnackbarAccess],
  props: {
    campaignId: {
      type: [String, Number],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    errors: [],
    campaign: {},
    loading: false,
    showEndCampaignConfirmDialog: false,
    showNewCampaignConfirmDialog: false,
    formContext: {},
  }),
  computed: {
    readonly() {
      return isToday(parseISO(this.campaign.redeemEndOn))
        ? false
        : isPast(parseISO(this.campaign.redeemEndOn));
    },
  },
  apollo: {
    campaign: {
      query: GET_CAMPAIGN_BY_ID,
      update: (data) => data.bouncebackCampaign,
      variables() {
        return { id: this.campaignId };
      },
      watchLoading(loading) {
        this.loading = loading;
      },
    },
  },
  methods: {
    setErrors(errors) {
      this.errors = errors;
    },
    onEndCampaignClick() {
      this.showEndCampaignConfirmDialog = true;
    },
    async onEndCampaignConfirmDialogOk() {
      const { data } = await this.$apollo.mutate({
        mutation: END_CAMPAIGN,
        variables: {
          input: {
            id: this.campaignId,
          },
        },
      });

      const { bouncebackCampaign, errors } = data.bouncebackCampaignEnd;

      if (errors.length > 0) {
        this.setErrors(errors);
        return;
      }

      this.showSnackbar({
        text: this.$t("bounceback.setup.editCampaign.endCampaignSuccess", {
          campaignName: bouncebackCampaign.name,
        }),
        color: "success",
      });
      this.$router.back();
    },
    async onNewCampaignConfirmDialogOk() {
      const { data } = await this.$apollo.mutate({
        mutation: CLONE_CAMPAIGN,
        variables: { input: this.formContext.values },
      });

      const { bouncebackCampaign, errors } = data.bouncebackCampaignClone;

      if (errors.length > 0) {
        this.setErrors(errors);
      } else {
        this.showSnackbar({
          text: this.$t("bounceback.setup.editCampaign.cloneCampaignSuccess"),
          color: "success",
        });

        this.setErrors([]);

        // if no errors, redirect to cloned campaign
        this.$router.replace({
          name: this.raiLinkTo.editCampaign,
          params: { campaignId: bouncebackCampaign.id },
        });
      }

      this.formContext.resolve();
      this.formContext = null;
    },
    async onSubmit(formContext) {
      const { values, changed, cloneable, resolve } = formContext;

      if (changed && cloneable) {
        this.formContext = formContext;
        this.showNewCampaignConfirmDialog = true;
        return;
      }

      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_CAMPAIGN,
        variables: { input: values },
      });

      const { errors } = data.bouncebackCampaignUpdate;

      if (errors.length > 0) {
        this.setErrors(errors);
      } else {
        this.showSnackbar({
          text: this.$t("bounceback.setup.editCampaign.updateCampaignSuccess"),
          color: "success",
        });

        this.setErrors([]);
      }

      resolve();
    },
  },
};
</script>
